define(['app','siteObj'], (app, siteObj) => {
  const accountHome = () => {
    const component = {};

    const _init = (element) => {
      if (document.referrer.indexOf("login.jsp") > -1) {
        component.loggedIn();
      } else if (document.referrer.indexOf("socialAuthentication.account") > -1) {
        component.loggedIn();
      }
    };

    const _loggedIn = () => {
      window.dataLayer.push({
        event: "elysiumEvent",
        eventData: {
          eventCategory: "login",
          method: window.dataLayer[0].visitorLoginMethod,
        }
      })

      window.dataLayer.push({
        event: "login",
        method: window.dataLayer[0].visitorLoginMethod,
        user: {
          user_id: window.dataLayer[0].visitorEmailAddress,
        },
        visitorLoginState: window.dataLayer[0].visitorLoginState
      })
    }

    component.init = _init;
    component.loggedIn = _loggedIn;
    return component;
  };

  return accountHome;
});
